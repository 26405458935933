import React from 'react'
import "./testmonial.css"
const Testmonial = () => {
    return (
        <>
            <div className="outerdiv ">
                <div className="px-6 lg:px-8 max-w-7xl mx-auto" id="design-innovation">
                    <div className="max-w-3xl lg:mb-24 mb-8">
                        <div className="lg:overflow-hidden">
                            <h2 className="font-bold text-white our-awards">
                                <span className='text-dark'>Our Testimonials</span>

                            </h2>
                        </div> <br />
                        <p className="text-secondary mb-12 lg:mb-24 text-base md:text-md lg:text-lg xl:text-xl 2xl:text-2xl">
                            Recognized globally for our industry-leading development expertise and innovative solutions. Creating innovative, user-friendly, and life-changing products is what we do!
                        </p>
                    </div>
                </div>
                <div className="innerdiv">

                    <div className="div1 eachdiv">
                        <div className="userdetails">
                            <div className="imgbox">
                                <img
                                    src="https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/image-daniel.jpg"
                                    alt=""
                                />
                            </div>
                            <div className="detbox">
                                <p className="name">ARY DIGITAL NETWORK</p>
                                <p className="designation">Zeeshan Khan</p>
                            </div>
                        </div>
                        <div className="review">
                            <h4>
                                “ Thanks to Spotcomm Global's, our media streaming services run smoothly and
                                without glitches. The team is always available and easily accessible via phone calls and WhatsApp
                                messages. Moreover, they're energetic and always ready to work, showing an excellent working
                                culture.”
                            </h4>

                        </div>
                    </div>
                    {/* div2 */}
                    <div className="div2 eachdiv">
                        <div className="userdetails">
                            <div className="imgbox">
                                <img
                                    src="https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/image-jonathan.jpg"
                                    alt=""
                                />
                            </div>
                            <div className="detbox">
                                <p className="name">CYAN Wireless (USA)</p>
                                <p className="designation">William Glenn</p>
                            </div>
                        </div>
                        <div className="review">
                         
                            <p>
                                “I have worked with this team for a while now on multiple different projects
                                related to my network
                                and infrastructure, each time they consistently go above and beyond my expectations and are
                                immediate when needed for urgent issues. Highly Recommended! ”
                            </p>
                        </div>
                    </div>
                    {/* div3 */}
                    <div className="div3 eachdiv">
                        <div className="userdetails">
                            <div className="imgbox">
                                <img
                                    src="https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/image-kira.jpg"
                                    alt=""
                                />
                            </div>
                            <div className="detbox">
                                <p className="name dark">Next Generation Esports</p>
                                <p className="designation dark">M. Amin</p>
                            </div>
                        </div>
                        <div className="review dark">
                          
                            <p>
                                “ Spotcomm Global’s top-notch managed network services played a pivotal role in
                    ensuring a seamless operation for us, resulting in zero downtime and lag during broadcasting events.
                    The team exhibited excellent project management delivering work on time and having efficient
                    communication. ”
                            </p>
                        </div>
                    </div>
                    {/* div4 */}
                    <div className="div4 eachdiv">
                        <div className="userdetails">
                            <div className="imgbox">
                                <img
                                    src="https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/image-jeanette.jpg"
                                    alt=""
                                />
                            </div>
                            <div className="detbox">
                                <p className="name dark">Inventocube LLC</p>
                                <p className="designation dark">Taha Hashmi</p>
                            </div>
                        </div>
                        <div className="review dark">
                   
                            <p>
                                “ Thanks to Spotcomm Global, the client got a more proper Wi-Fi setup for their
                    growing business needs, allowing them to achieve amazing results. Moreover, the team provided
                    excellent advice and delivered items on time, showing impressive commitment, work ethic, and
                    knowledge. ”
                            </p>
                        </div>
                    </div>
                    {/* div5 */}
                    <div className="div5 eachdiv">
                        <div className="userdetails">
                            <div className="imgbox">
                                <img
                                    src="https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/image-patrick.jpg"
                                    alt=""
                                />
                            </div>
                            <div className="detbox">
                                <p className="name">Patrick Abrams</p>
                                <p className="designation">Verified Graduate</p>
                            </div>
                        </div>
                        <div className="review">
                            <h4>
                                Awesome teaching support from TAs who did the bootcamp themselves.
                                Getting guidance from them and learning from their experiences was
                                easy.
                            </h4>
                            <p>
                                “ The staff seem genuinely concerned about my progress which I find
                                really refreshing. The program gave me the confidence necessary to
                                be able to go out in the world and present myself as a capable
                                junior developer. The standard is above the rest. You will get the
                                personal attention you need from an incredible community of smart
                                and amazing people. ”
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </>

    )
}

export default Testmonial