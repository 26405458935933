import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/pagination';
import 'swiper/css';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Scrollbar } from 'swiper/modules';

const Award = () => {
    // Array of awards data
    const awards = [
        {
            href: "",
            imgSrc: "/abbott.png",
            alt: "Designrush",
            rating: "4.9",
            description: "Recognized among top mobile app development companies 2024",
        },
        {
            href: "",
            imgSrc: "/bc.png",
            alt: "Clutch",
            rating: "4.9",
            description: "Acclaimed as a top-rated software development company 2024",
        },
        {
            href: "",
            imgSrc: "/valorant.webp",
            alt: "Rightfirms",
            rating: "4.9",
            description: "Ranked among leading app development service providers 2024",
        },
        {
            href: "",
            imgSrc: "/ibex.png",
            alt: "Goodfirms",
            rating: "5.0",
            description: "Acknowledged among the top software consulting experts 2024",
        },
        {
            href: "",
            imgSrc: "/Ramada.png", // Add the correct image source
            alt: "Rightfirms",
            rating: "4.9",
            description: "Ranked among leading app development service providers 2024",
        },
        {
            href: "",
            imgSrc: "/searle.png", // Add the correct image source
            alt: "Goodfirms",
            rating: "5.0",
            description: "Acknowledged among the top software consulting experts 2024",
        },
    ];

    return (
        <section
            className="design-innovation bg-dark-theme overflow-x-hidden sm:overflow-x-auto  "
            id="achievements"
        >
            <div className="lg:py-[10.5rem] md:py-24 py-14">
                <div className="px-6 lg:px-8 max-w-7xl mx-auto" id="design-innovation">
                    <div className="max-w-3xl lg:mb-24 mb-8">
                        <div className="lg:overflow-hidden">
                            <h2 className="font-bold text-white our-award">
                                <span>Our Awards And</span>
                                <span className="text-theme"> Recognitions.</span>
                            </h2>
                        </div> <br />
                        <p className="text-secondary mb-12 lg:mb-24 text-base md:text-md lg:text-lg xl:text-xl 2xl:text-2xl">
                            Recognized globally for our industry-leading development expertise and innovative solutions. Creating innovative, user-friendly, and life-changing products is what we do!
                        </p>
                    </div>
                </div>

                {/* Swiper Component */}
                <Swiper
                    modules={[Autoplay]}
                    spaceBetween={30}
                    slidesPerView={1}

                    loop={true}
                    autoplay={{
                        delay: 0, // Continuous autoplay without a delay
                        disableOnInteraction: false, // Keeps autoplay even when interacting
                        pauseOnMouseEnter: false,
                    }}
                    speed={3000} // Adjust speed for smoothness (in ms)
                    breakpoints={{
                        640: {
                            slidesPerView: 1,
                            spaceBetween: 10,
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        1024: {
                            slidesPerView: 5,
                            spaceBetween: 30,
                        },
                    }}
                >
                    {awards.map((award, index) => (
                        <SwiperSlide key={index}>

                            <article className="bg-gradient-to-r from-[#050505] to-[#201F21] mx-2 md:mx-4 px-6 py-8 lg:py-[35px] lg:mx-0 lg:px-6  rounded-3xl lg:h-64 h-52 flex flex-col justify-between">
                                <div className="flex justify-between">
                                    <img
                                        src={award.imgSrc}
                                        alt={award.alt}
                                        style={{ height: 50, width: 100 }}
                                    />
                                    <div className="flex items-center">
                                        <img
                                            className="h-[14px] w-[14px] sm:h-[15px] sm:w-[15px] lg:h-[16px] lg:w-[16px] 2xl:h-[19px] 2xl:w-[19px] mb-1"
                                            src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOSAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuNSAwLjQwNjI1TDExLjYzMjkgNi45NzA1OUgxOC41MzVMMTIuOTUxMSAxMS4wMjc2TDE1LjA4NCAxNy41OTE5TDkuNSAxMy41MzQ5TDMuOTE2MDQgMTcuNTkxOUw2LjA0ODkyIDExLjAyNzZMMC40NjQ5NjMgNi45NzA1OUg3LjM2NzEyTDkuNSAwLjQwNjI1WiIgZmlsbD0iIzJFRDA2RSIvPgo8L3N2Zz4K"
                                            alt="Star"
                                        />
                                        <p className="font-bold ml-2 text-white text-sm lg:text-md xl:text-lg 2xl:text-xl">
                                            {award.rating}
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <p className="text-neutral-500 text-sm lg:text-md xl:text-lg 2xl:text-xl">
                                        {award.description}
                                    </p>
                                </div>
                            </article>

                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </section>
    );
};

export default Award;
