import logo from './logo.svg';
import './App.css';
import Landingpage from './pages/Landingpage';
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Demo from './components/Navbar';
import Businesssolution from './pages/Businesssolution';
import Cybersecurity from './pages/Cybersecurity';
import Consulting from './pages/Consulting';
import Demo1 from './pages/Demo2';
import NetworkInfrastructure from './pages/NetworkInfrastructure';
import NetworkDesign from './pages/NetworkDesign';
import StaffArugment from './pages/StaffArugment';
import CaseStudies from './pages/CaseStudies';
// import Demo from './pages/Demo1';
// import App1 from './pages/App (1)';


function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Landingpage />} />
        <Route path="/business" element={<Businesssolution />} />
        <Route path="/cyber-security" element={<Cybersecurity />} />
        <Route path="/consulting" element={<Consulting />} />
        <Route path="/NetworkInfrastructureDeployment" element={<NetworkInfrastructure />} />
        <Route path="/NetworkDesign" element={<NetworkDesign />} />
        <Route path="/staffargument" element={<StaffArugment  />} />
        <Route path="/case-studies" element={<CaseStudies  />} />
      </Routes>
    </div>
  );
}

export default App;
