import React from 'react'
import Header from '../components/Header'
import Demo from '../components/Navbar';

const Demo2 = () => {
    const services = [
        "CIO Advisory",
        "Sustainability Services",
        "Talent and Change Management",
        "Digital Transformation",
        "Banking",
        "Energy",
        "Utilities",
        "Consumer Packaged Goods",
        "Healthcare",
        "Life Sciences",
        "Retail",
        "Telecom"
    ];

    return (
        <div>
            <Header />
            <div className="  responsivegrid">
                <div
                    className="cmp- cmp---939597271 "
                    data-type="grid"
                    id="-296a049e61"
                >
                    <div className="aem-Grid aem-Grid--12 aem-Grid--default--12 ">

                        <div className=" responsivegrid aem-GridColumn aem-GridColumn--default--12">
                            <div
                                className="cmp- cmp---220486293 "
                                data-type="grid"
                                id="-3aab8fef1f"
                            >
                                <div className="aem-Grid aem-Grid--12 aem-Grid--default--12 ">
                                    <section id="heros" class="heros section dark-background">

                                        <img src="/consulting.png" alt="" data-aos="fade-in" />

                                        <div class="container text-center" data-aos="fade-up" data-aos-delay="100">
                                            <div class="row justify-content-center">
                                                <div class="col-lg-8">
                                                    <h2>Consulting meet transformation</h2>
                                                </div>
                                            </div>
                                        </div>

                                    </section>
                                    <div className=" responsivegrid --width-wide --full-width aem-GridColumn aem-GridColumn--default--12">
                                        <div
                                            className="cmp- cmp---1058722595 "
                                            data-type="grid"
                                            id="-4af8be69f5"
                                        >
                                            <div className="aem-Grid aem-Grid--12 aem-Grid--default--12 aem-Grid--phone--12 ">
                                                <div className=" responsivegrid --width-regular --full-width aem-GridColumn aem-GridColumn--default--12">
                                                    <div
                                                        className="cmp- cmp--1070173647 "
                                                        data-type="simple"
                                                        id="-aee7dd742e"
                                                        style={{
                                                            backgroundColor: '#362358'
                                                        }}
                                                    >
                                                        <div className="separator">
                                                            <div
                                                                className="cmp-separator cmp-separator-1957592741"
                                                                id="separator-6f8943cc75"
                                                                style={{
                                                                    background: '#362358'
                                                                }}
                                                            />
                                                            <style
                                                                dangerouslySetInnerHTML={{
                                                                    __html: '.cmp-separator-1957592741 {height:40px;}'
                                                                }}
                                                                data-path="/content/nexus/en/service-lines/consulting/jcr:content/root////separator"
                                                            />
                                                        </div>
                                                        <div className="text">
                                                            <div
                                                                className="cmp-text"
                                                                id="text-5ad5ae65a0" data-aos="fade-right" data-aos-delay="100"
                                                            >
                                                                <p
                                                                    style={{
                                                                        textAlign: 'center'
                                                                    }}
                                                                >
                                                                    <span
                                                                        style={{
                                                                            display: 'inline',
                                                                            fontSize: '24.0px'
                                                                        }}
                                                                    >
                                                                        <b>
                                                                            <span className='w-50'
                                                                                style={{
                                                                                    color: 'rgb(255,255,255)',
                                                                                    display: 'inline',
                                                                                 
                                                                                }}
                                                                            >
                                                                                Spotcomm harnesses cutting-edge technologies and extensive industry expertise to support clients in driving business transformation and developing robust, next-generation operating models. Our consulting practice spans various sectors, including financial services, energy, utilities, healthcare and life sciences, sustainability, talent management and change, CIO advisory, and consumer businesses. Explore some of the latest insights from Spotcomm Consulting, Full Stride Cloud, Engineering Edge, Lab45, Designit, and Capco.
                                                                            </span>
                                                                        </b>
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="separator">
                                                            <div
                                                                className="cmp-separator cmp-separator-1772701903"
                                                                id="separator-383956da6f"
                                                                style={{
                                                                    background: '#362358'
                                                                }}
                                                            />
                                                            <style
                                                                dangerouslySetInnerHTML={{
                                                                    __html: '.cmp-separator-1772701903 {height:40px;}'
                                                                }}
                                                                data-path="/content/nexus/en/service-lines/consulting/jcr:content/root////separator_copy"
                                                            />
                                                        </div>
                                                        <div className="cmp-__button-">
                                                        </div>
                                                        <div
                                                            className="cmp-__popup-background"
                                                            id="popup-background--aee7dd742e"
                                                        />
                                                        <div
                                                            className="cmp-__data"
                                                            data-expandaspopup="false"
                                                            data-expandbuttonlabel="Read More"
                                                            data-maxitems="-2"
                                                            data-maxitemsmobile="-2"
                                                            data-maxitemstablet="-2"
                                                            id="data--aee7dd742e"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="teaser teaser--full-size aem-GridColumn aem-GridColumn--default--12" data-aos="fade-right" data-aos-delay="200">
                                                    <div className="cmp-teaser cmp-teaser--1095483950    ">
                                                        <div
                                                            className="cmp-teaser__content"
                                                            style={{}}
                                                        >
                                                            <div className="cmp-teaser__content-wrapper">
                                                                <h2 className="cmp-teaser__title  ">
                                                                    <a
                                                                        className="cmp-teaser__title-link"
                                                                        href="https://lab45thinktank.com/article/business-process-services-in-the-era-of-generative-artificial-intelligence/"
                                                                        rel="nofollow"
                                                                        target="_blank"
                                                                    >
                                                                        Business Process Services in the Era of GenAI
                                                                    </a>
                                                                </h2>
                                                                <div className="cmp-teaser__description">
                                                                    <p>
                                                                        In today's fast-paced business landscape, staying ahead requires more than just keeping up with the latest trends—it demands innovation. This is particularly true in the realm of BPS, where efficiency, accuracy, and adaptability reign supreme. In our latest special report, we delve into the transformative power of GenAI and its profound implications for the future of BPS.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="cmp-teaser__image">
                                                            <img src="/con1.png" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="teaser teaser--full-size teaser--image-left aem-GridColumn aem-GridColumn--default--12" data-aos="fade-right" data-aos-delay="200">
                                                    <div className="cmp-teaser cmp-teaser--638358011    ">
                                                        <div
                                                            className="cmp-teaser__content"
                                                            style={{}}
                                                        >
                                                            <div className="cmp-teaser__content-wrapper">
                                                                <h2 className="cmp-teaser__title  ">
                                                                    <a
                                                                        className="cmp-teaser__title-link"
                                                                        href="https://www.capco.com/intelligence/capco-intelligence/will-the-new-virtual-banks-reshape-the-thailand-banking-industry"
                                                                        rel="nofollow"
                                                                        target="_blank"
                                                                    >
                                                                        Thailand’s Bank of the Future : Will the New Virtual Banks Reshape the Industry?
                                                                    </a>
                                                                </h2>
                                                                <div className="cmp-teaser__description">
                                                                    <p>
                                                                        Thailand’s banking sector is set for transformation as new virtual banking providers aim to innovate and better serve underserved consumers and SMEs. The impending challenges, opportunities, and changes in customer expectations can inform financial institutions operating far beyond Thailand’s borders.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="cmp-teaser__image">
                                                            <img src="/con2.png" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="teaser teaser--full-size aem-GridColumn aem-GridColumn--default--12" data-aos="fade-right" data-aos-delay="200">
                                                    <div className="cmp-teaser cmp-teaser--718471742    ">
                                                        <div
                                                            className="cmp-teaser__content"
                                                            style={{}}
                                                        >
                                                            <div className="cmp-teaser__content-wrapper">
                                                                <h2 className="cmp-teaser__title  ">
                                                                    <a
                                                                        className="cmp-teaser__title-link"
                                                                        href="/cloud/joint-report-with-forbes-how-to-master-cloud-economics-and-harness-the-power-of-ai/"
                                                                        target="_blank"
                                                                    >
                                                                        How to Master Cloud Economics and Harness the Power of AI
                                                                    </a>
                                                                </h2>
                                                                <div className="cmp-teaser__description">
                                                                    <p>
                                                                        Our recent groundbreaking report with Forbes presents new insights about how to approach cloud computing resources in the age of AI.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="cmp-teaser__image">
                                                            <img src="/con3.png" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="teaser teaser--full-size teaser--image-left aem-GridColumn aem-GridColumn--default--12" data-aos="fade-right" data-aos-delay="200">
                                                    <div
                                                        className="cmp-teaser cmp-teaser-2010868465    "
                                                        id="insights"
                                                    >
                                                        <div
                                                            className="cmp-teaser__content"
                                                            style={{}}
                                                        >
                                                            <div className="cmp-teaser__content-wrapper">
                                                                <h2 className="cmp-teaser__title  ">
                                                                    <a
                                                                        className="cmp-teaser__title-link"
                                                                        href="https://www.designit.com/stories/point-of-view/why-should-insurance-companies-rethink-their-loyalty-strategies"
                                                                        rel="nofollow"
                                                                        target="_blank"
                                                                    >
                                                                        Why Insurance Companies Need to Rethink Loyalty Strategies
                                                                    </a>
                                                                </h2>
                                                                <div className="cmp-teaser__description">
                                                                    <p>
                                                                        Insurance companies are replacing outdated engagement with innovative strategies that prioritize genuine customer care and satisfaction. Learn about the forces driving change, the foundational pillars for a meaningful relationship model, and the exciting opportunities that lie ahead.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="cmp-teaser__image">
                                                            <img src="/con4.png" />
                                                        </div>
                                                    </div>
                                                </div>
                                          
                                                <div className="teaser teaser--full-size teaser--image-left teaser--card aem-GridColumn--default--none aem-GridColumn--phone--none aem-GridColumn--phone--12 aem-GridColumn aem-GridColumn--default--6 aem-GridColumn--offset--phone--0 aem-GridColumn--offset--default--0">
                                                    <div className="cmp-teaser cmp-teaser--1828042053  cmp-teaser--show-action  ">
                                                        <div
                                                            className="cmp-teaser__content"
                                                            style={{}}
                                                        >
                                                            <div className="cmp-teaser__content-wrapper">
                                                                <h2 className="cmp-teaser__title  ">
                                                                    <div className="cmp-teaser__title">
                                                                        Data Analytics Transform the Airport Experience
                                                                        <br />
                                                                    </div>
                                                                </h2>
                                                                <div className="cmp-teaser__description">
                                                                    <p>
                                                                        Enhancing the passenger experience has become a top priority for busy airports around the world seeking to increase non-aero revenue. They key is finding ways to leverage vast amounts of underutilized data to...
                                                                    </p>
                                                                </div>
                                                                <div className="cmp-teaser__action-">
                                                                    <div
                                                                        className="cmp-teaser__action-background"
                                                                        data-style="background-color:#7470a6; "
                                                                        style={{
                                                                            backgroundColor: '#7470a6'
                                                                        }}
                                                                    >
                                                                        <a
                                                                            className="cmp-teaser__action-link"
                                                                            href="/engineering-construction-operations/data-analytics-transforming-the-airport-experience-and-increasing-nonaero-revenues/"
                                                                            style={{
                                                                                backgroundColor: '#7470a6'
                                                                            }}
                                                                        >
                                                                            Read More
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="cmp-teaser__image">
                                                            <img src="/con3.png" />
                                                        </div>
                                                    </div>
                                                    <style
                                                        dangerouslySetInnerHTML={{
                                                            __html: '.cmp-teaser--1828042053 .cmp-teaser__content{color:rgb(255,255,255);background-color:#7470a6;}'
                                                        }}
                                                        data-path="/content/nexus/en/service-lines/consulting/jcr:content/root///teaser_copy_32048284_712145262"
                                                    />
                                                </div>
                                                <div className="teaser teaser--full-size teaser--image-left teaser--card aem-GridColumn--default--none aem-GridColumn--phone--none aem-GridColumn--phone--12 aem-GridColumn aem-GridColumn--default--6 aem-GridColumn--offset--phone--0 aem-GridColumn--offset--default--0">
                                                    <div className="cmp-teaser cmp-teaser--971658057  cmp-teaser--show-action  ">
                                                        <div
                                                            className="cmp-teaser__content"
                                                            style={{}}
                                                        >
                                                            <div className="cmp-teaser__content-wrapper">
                                                                <h2 className="cmp-teaser__title  ">
                                                                    <div className="cmp-teaser__title">
                                                                        An Interview with the Co-Founders of FemTech Lab
                                                                        <br />
                                                                    </div>
                                                                </h2>
                                                                <div className="cmp-teaser__description">
                                                                    <p>
                                                                        The latest Designit interview reinforces that innovation doesn't happen inside a computer, but inside our minds and imagination. But the pressure to adapt quickly to market changes makes it difficult to focus on the right...
                                                                    </p>
                                                                </div>
                                                                <div className="cmp-teaser__action-">
                                                                    <div
                                                                        className="cmp-teaser__action-background"
                                                                        data-style="background-color:#7470a6; "
                                                                        style={{
                                                                            backgroundColor: '#7470a6'
                                                                        }}
                                                                    >
                                                                        <a
                                                                            className="cmp-teaser__action-link"
                                                                            href="/consulting/design-the-future-featuring-femtech-cofounders/"
                                                                            style={{
                                                                                backgroundColor: '#7470a6'
                                                                            }}
                                                                        >
                                                                            <span style={{}}>
                                                                                Read
                                                                            </span>
                                                                            More
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="cmp-teaser__image">
                                                            <img src="/con4.png" />
                                                        </div>
                                                    </div>
                                                    <style
                                                        dangerouslySetInnerHTML={{
                                                            __html: '.cmp-teaser--971658057 .cmp-teaser__content{color:rgb(255,255,255);background-color:#7470a6;}'
                                                        }}
                                                        data-path="/content/nexus/en/service-lines/consulting/jcr:content/root///teaser_copy_32048284_1027937634"
                                                    />
                                                </div>
                                                <div className="teaser teaser--full-size teaser--card aem-GridColumn--default--none aem-GridColumn--phone--none aem-GridColumn--phone--12 aem-GridColumn aem-GridColumn--default--6 aem-GridColumn--offset--phone--0 aem-GridColumn--offset--default--0">
                                                    <div className="cmp-teaser cmp-teaser--13409574  cmp-teaser--show-action  ">
                                                        <div
                                                            className="cmp-teaser__content"
                                                            style={{}}
                                                        >
                                                            <div className="cmp-teaser__content-wrapper">
                                                                <h2 className="cmp-teaser__title  ">
                                                                    <div className="cmp-teaser__title">
                                                                        Global Insurance Survey 2023
                                                                    </div>
                                                                </h2>
                                                                <div className="cmp-teaser__description">
                                                                    <p>
                                                                        Capco's research sheds light on the role of data and personalization as insurers map their optimal path forward. The insights and recommendations gleaned from input by 13,750 respondents can help insurers navigate an increasingly...
                                                                    </p>
                                                                    <p />
                                                                </div>
                                                                <div className="cmp-teaser__action-">
                                                                    <div
                                                                        className="cmp-teaser__action-background"
                                                                        data-style="background-color:#7470a6; "
                                                                        style={{
                                                                            backgroundColor: '#7470a6'
                                                                        }}
                                                                    >
                                                                        <a
                                                                            className="cmp-teaser__action-link"
                                                                            href="https://www.capco.com/intelligence/capco-intelligence/global-insurance-survey-2023"
                                                                            rel="nofollow"
                                                                            style={{
                                                                                backgroundColor: '#7470a6'
                                                                            }}
                                                                        >
                                                                            <span style={{}}>
                                                                                Read
                                                                            </span>
                                                                            More
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="cmp-teaser__image">
                                                            <img src="/con9.png" />
                                                        </div>
                                                    </div>
                                                    <style
                                                        dangerouslySetInnerHTML={{
                                                            __html: '.cmp-teaser--13409574 .cmp-teaser__content{color:rgb(255,255,255);background-color:#7470a6;}'
                                                        }}
                                                        data-path="/content/nexus/en/service-lines/consulting/jcr:content/root///teaser_copy_32048284_1621792052"
                                                    />
                                                </div>
                                                <div className="teaser teaser--full-size teaser--card aem-GridColumn--default--none aem-GridColumn--phone--none aem-GridColumn--phone--12 aem-GridColumn aem-GridColumn--default--6 aem-GridColumn--offset--phone--0 aem-GridColumn--offset--default--0">
                                                    <div className="cmp-teaser cmp-teaser--1606096698  cmp-teaser--show-action  ">
                                                        <div
                                                            className="cmp-teaser__content"
                                                            style={{}}
                                                        >
                                                            <div className="cmp-teaser__content-wrapper">
                                                                <h2 className="cmp-teaser__title  ">
                                                                    <div className="cmp-teaser__title">
                                                                        Championing the Chatbot
                                                                        <br />
                                                                    </div>
                                                                </h2>
                                                                <div className="cmp-teaser__description">
                                                                    <p>
                                                                        Chatbots and other virtual assistants will soon be much more ubiquitous across the employee experience. Change management will de-risk their implementation and promote broader acceptance.
                                                                    </p>
                                                                    <p>
                                                                        <br />
                                                                    </p>
                                                                    <p />
                                                                </div>
                                                                <div className="cmp-teaser__action-">
                                                                    <div
                                                                        className="cmp-teaser__action-background"
                                                                        data-style="background-color:#7470a6; "
                                                                        style={{
                                                                            backgroundColor: '#7470a6'
                                                                        }}
                                                                    >
                                                                        <a
                                                                            className="cmp-teaser__action-link"
                                                                            href="/consulting/championing-the-chatbot/"
                                                                            style={{
                                                                                backgroundColor: '#7470a6'
                                                                            }}
                                                                        >
                                                                            Read More
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="cmp-teaser__image">
                                                            <img src="/con6.png" />
                                                        </div>
                                                    </div>
                                                    <style
                                                        dangerouslySetInnerHTML={{
                                                            __html: '.cmp-teaser--1606096698 .cmp-teaser__content{color:rgb(255,255,255);background-color:#7470a6;}'
                                                        }}
                                                        data-path="/content/nexus/en/service-lines/consulting/jcr:content/root///teaser_copy_32048284_1404454339"
                                                    />
                                                </div>
                                                <div className="teaser teaser--full-size teaser--image-left teaser--card aem-GridColumn--default--none aem-GridColumn--phone--none aem-GridColumn--phone--12 aem-GridColumn aem-GridColumn--default--6 aem-GridColumn--offset--phone--0 aem-GridColumn--offset--default--0">
                                                    <div className="cmp-teaser cmp-teaser--786989466  cmp-teaser--show-action  ">
                                                        <div
                                                            className="cmp-teaser__content"
                                                            style={{}}
                                                        >
                                                            <div className="cmp-teaser__content-wrapper">
                                                                <h2 className="cmp-teaser__title  ">
                                                                    <div className="cmp-teaser__title">
                                                                        Decarbonize and Grow
                                                                        <br />
                                                                    </div>
                                                                </h2>
                                                                <div className="cmp-teaser__description">
                                                                    <p>
                                                                        Carbon-reduction initiatives are expanding in scope and will inevitably evolve further in the future. While decarbonizing the value chain may seem like a burden, viewed through the proper lense and strategic vision, it can bring exciting...
                                                                    </p>
                                                                    <p />
                                                                </div>
                                                                <div className="cmp-teaser__action-">
                                                                    <div
                                                                        className="cmp-teaser__action-background"
                                                                        data-style="background-color:#7470a6; "
                                                                        style={{
                                                                            backgroundColor: '#7470a6'
                                                                        }}
                                                                    >
                                                                        <a
                                                                            className="cmp-teaser__action-link"
                                                                            href="/utilities/de-carbonize-and-grow-enabling-a-digital-led-move/"
                                                                            style={{
                                                                                backgroundColor: '#7470a6'
                                                                            }}
                                                                        >
                                                                            Read More
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="cmp-teaser__image">
                                                            <img src="/con7.png" />
                                                        </div>
                                                    </div>
                                                    <style
                                                        dangerouslySetInnerHTML={{
                                                            __html: '.cmp-teaser--786989466 .cmp-teaser__content{color:rgb(255,255,255);background-color:#7470a6;}'
                                                        }}
                                                        data-path="/content/nexus/en/service-lines/consulting/jcr:content/root///teaser_copy_32048284"
                                                    />
                                                </div>
                                                <div className="teaser teaser--full-size teaser--image-left teaser--card aem-GridColumn--default--none aem-GridColumn--phone--none aem-GridColumn--phone--12 aem-GridColumn aem-GridColumn--default--6 aem-GridColumn--offset--phone--0 aem-GridColumn--offset--default--0">
                                                    <div className="cmp-teaser cmp-teaser--1182465507  cmp-teaser--show-action  ">
                                                        <div
                                                            className="cmp-teaser__content"
                                                            style={{}}
                                                        >
                                                            <div className="cmp-teaser__content-wrapper">
                                                                <h2 className="cmp-teaser__title  ">
                                                                    <div className="cmp-teaser__title">
                                                                        Turning Energy Retailers into Customer-Centric Brands
                                                                        <br />
                                                                    </div>
                                                                </h2>
                                                                <div className="cmp-teaser__description">
                                                                    <p>
                                                                        As oil and gas retailers consider the renewables dominated future, an enhanced customer experience will be key to attracting retail fuel customers, building new revenue streams,...
                                                                    </p>
                                                                    <p />
                                                                </div>
                                                                <div className="cmp-teaser__action-">
                                                                    <div
                                                                        className="cmp-teaser__action-background"
                                                                        data-style="background-color:#7470a6; "
                                                                        style={{
                                                                            backgroundColor: '#7470a6'
                                                                        }}
                                                                    >
                                                                        <a
                                                                            className="cmp-teaser__action-link"
                                                                            href="/oil-and-gas/how-energy-retailers-can-become-customer-centric-brands/"
                                                                            style={{
                                                                                backgroundColor: '#7470a6'
                                                                            }}
                                                                        >
                                                                            <span style={{}}>
                                                                                Read
                                                                            </span>
                                                                            More
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="cmp-teaser__image">
                                                            <img src="/con8.png" />
                                                        </div>
                                                    </div>
                                                    <style
                                                        dangerouslySetInnerHTML={{
                                                            __html: '.cmp-teaser--1182465507 .cmp-teaser__content{color:rgb(255,255,255);background-color:#7470a6;}'
                                                        }}
                                                        data-path="/content/nexus/en/service-lines/consulting/jcr:content/root///teaser_copy_32048284_1056046798"
                                                    />
                                                </div>




                                            </div>


                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <section className="services3">
                <h2>
                    <span className="highlight">Consulting</span> Services
                </h2>
                <div className="services3-grid">
                    {services.map((service, index) => (
                        <div key={index} className="service3-item">
                            {service}
                        </div>
                    ))}
                </div>
            </section>
            <Demo />
        </div>
    )
}

export default Demo2