import React from 'react'

const Blogcard = () => {
    return (
        <>
        <br /><br />
           
            <div className="container1">
                <div className="card">
                    <div className="card-headers">
                    <img src="/blog (1).png" alt="rover" />
                    </div>
                    <div className="card-body">
                       
                        <h4>
                        Network Infrastructure Monitoring

                        </h4>
                        <p>
                            An exploration into the truck's polarising design
                        </p>
                        <div className="user">
                        <button className="service-btn" style={{background: "#7F3E98"}}>Read More</button>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-headers">
                    <img src="/blog (2).png" alt="rover" />
                    </div>
                    <div className="card-body">
                   
                        <h4>
                            How to Keep Going When You Don’t Know What’s Next
                        </h4>
                        <p>
                            The future can be scary, but there are ways to
                            deal with that fear.
                        </p>
                        <div className="user">
                        <button className="service-btn" style={{background: "#7F3E98"}}>Read More</button>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-headers">
                    <img src="/blog (1).png" alt="rover" />
                    </div>
                    <div className="card-body">
                     
                        <h4>
                            10 Rules of Dashboard Design
                        </h4>
                        <p>
                            Dashboard Design Guidelines
                        </p>
                        <div className="user">
                        <button className="service-btn" style={{background: "#7F3E98"}}>Read More</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container1'>
                <div className=" card">
                    <div className="card-headers">
                    <img src="/blog (2).png" alt="rover" />
                    </div>
                    <div className="card-body">
                       
                        <h4>
                            Why is the Tesla Cybertruck designed the way it
                            is?
                        </h4>
                        <p>
                            An exploration into the truck's polarising design
                        </p>
                        <div className="user">
                        <button className="service-btn" style={{background: "#7F3E98"}}>Read More</button>
                        </div>
                    </div>
                </div>
                <div className=" card">
                    <div className="card-headers">
                        <img src="/blog (1).png" alt="rover" />
                    </div>
                    <div className="card-body">
                        
                        <h4>
                            Why is the Tesla Cybertruck designed the way it
                            is?
                        </h4>
                        <p>
                            An exploration into the truck's polarising design
                        </p>
                        <div className="user">
                                  <button className="service-btn" style={{background: "#7F3E98"}}>Read More</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Blogcard