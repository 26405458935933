import React, { useEffect, useState, useRef } from 'react';
import Navbar from '../components/Navbar'
import Header from '../components/Header'
import { useMediaQuery } from 'react-responsive'
import AOS from 'aos';
import $ from 'jquery'
import Footer from '../components/Footer';
import Demo1 from './Demo1';
import Scroller from './Scroller';
import CookieConsent from '../components/CookieConsent';
import Demo from '../components/Navbar';
import Award from '../components/Award';
import Testmonial from '../components/Testmonial';
window.jquery = window.$ = $

AOS.init();

const Landingpage = () => {
    const Desktop = ({ children }) => {
        const isDesktop = useMediaQuery({ minWidth: 992 })
        return isDesktop ? children : null
    }
    const Tablet = ({ children }) => {
        const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
        return isTablet ? children : null
    }
    const Mobile = ({ children }) => {
        const isMobile = useMediaQuery({ maxWidth: 900 })
        return isMobile ? children : null
    }
    const Default = ({ children }) => {
        const isNotMobile = useMediaQuery({ minWidth: 768 })
        return isNotMobile ? children : null
    }


    return (
        <>

            <div className='parent'>
                {/* <Navbar /> */}
                <Header />

                <section id="hero" class="hero section dark-background">
                    <video autoPlay muted loop className='banner-video' style={{
                        position: 'absolute',
                        top: '0px',
                        left: '0',
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        zIndex: 1,
                        opacity: 0.9,

                    }}>
                        <source src='/video.mp4' type='video/mp4' />

                    </video>



                    <Desktop>
                        <div class="container text-center bannerh2" data-aos="fade-up" data-aos-delay="100">
                            <div class="row justify-content-center">
                                <div class="col-lg-8" data-aos="fade-right" data-aos-delay="500">
                                    <h2 data-aos="fade-down" data-aos-delay="100">Be Seamless with  Our    Managed  Services </h2><br />
                                    <p data-aos="fade-left" data-aos-delay="100">An insight report by Spotcomm FullStride Cloud & Forbes. </p><br />
                                    <a data-aos="fade-right" data-aos-delay="100" href="#about" class="btn-get-started ">Learn More</a>
                                </div>
                            </div>
                        </div>

                        <section id="section05" className="demo">
                            <a href="#section06" className=''>Scroll<span></span></a>
                        </section>
                    </Desktop>
                    <Mobile>
                        <div class="container text-center " data-aos="fade-up" data-aos-delay="100">
                            <div class="row justify-content-center">
                                <div class="col-lg-8" data-aos="fade-right" data-aos-delay="500">
                                    <h2 data-aos="fade-down" data-aos-delay="100">Be Seamless with  Our    Managed  Services </h2><br />
                                    <p data-aos="fade-left" data-aos-delay="100">An insight report by Spotcomm FullStride Cloud & Forbes. </p><br />
                                    <a data-aos="fade-right" data-aos-delay="100" href="#about" class="btn-get-started ">Learn More</a>
                                </div>
                            </div>
                        </div>
                    </Mobile>
                </section>

                <Scroller />
                <div id='section06'>


                    <Demo1 />
                </div>

                {/* <div className=' row banner3 '>
                    <div className='col-lg-6 heading'>
                        <br /> <br />
                        <h2 className='h2'>Dreamworld Resort Outsources</h2>
                        <p className='text-center'>Dreamworld Resort Outsources IT to Spotcomm <br /> to reduce cost and upgrade entire Network.</p>
                    </div> <br />
                    <div className='col-lg-6 '>
                        <img src='/video.png' className='w-75 mx-auto d-flex justify-content-center' />
                    </div>
                    <br />
                </div> */}

                <Award />
                {/* <div className=' row banner3 ' id='section07'>
                    <div className='col-lg-6 heading'>
                        <br /> <br />
                        <h2 className='h2'>Dreamworld Resort <br /> Outsources</h2>
                        <p>Dreamworld Resort Outsources IT to Spotcomm <br /> to reduce cost and upgrade entire Network.</p>
                    </div>   <br />
                    <div className='col-lg-6 '>
                        <img src='/video.png' className='w-75 mx-auto d-flex justify-content-center' />
                    </div>
                    <br /> <br />
                </div> */}
                <div className=' row '>
                    <div className='col-lg-4   position-relative' data-aos="fade-up">
                        <img src='/image-1.png' className='w-100 ' />
                    </div>
                    <div className='col-lg-4   position-relative' data-aos="fade-up">
                        <img src='/image-2.png' className='w-100 ' />
                    </div>
                    <div className='col-lg-4   position-relative' data-aos="fade-up">
                        <img src='/image-3.png' className='w-100 ' />
                    </div>
                    <div className='col-lg-4   position-relative' data-aos="fade-up">
                        <img src='/image-4.png' className='w-100 ' />
                    </div>
                    <div className='col-lg-4   position-relative' data-aos="fade-up">
                        <img src='/image-5.png' className='w-100 ' />
                    </div>
                    <div className='col-lg-4   position-relative' data-aos="fade-up">
                        <img src='/image.png' className='w-100 ' />
                    </div>
                </div><br />
                <Testmonial />

                <Demo />
                {/* <Footer /> */}
                <CookieConsent />
            </div>

        </>
    )
}

export default Landingpage