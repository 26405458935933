import React, { useRef, useCallback } from 'react'
import Header from '../components/Header'
import "./business.css"
import Demo from '../components/Navbar';

const CaseStudies = () => {
    const caseStudies = [
        { title: "ARY Digital Network Case Study", image: "/case4.png" },
        { title: "Bar B Q Tonight Case Study",image: "/case2.png"  },
        { title: "Cooperative Computing Case Study", image: "/case3.png"  },
        // { title: "Cooperative Computing Case Study", image: "/case1.png"  },
        { title: "Garena Free Fire Case Study", image: "/case5.png"  },
        { title: "Dreamworld Resort Case Study", image: "/case6.png"  },
        { title: "Family Choice of New York (FCNY) Case Study", image: "/case7.png"  },
        { title: "British Council Case Study", image: "/case1.png"  }
    ];
    return (
        <div>

            <Header />
            <section id="heros" class="heros section dark-background">

                <img src="/case.png" alt="" data-aos="fade-in" />

                <div class="container text-center" data-aos="fade-up" data-aos-delay="100">
                    <div class="row justify-content-center">
                        <div class="col-lg-8">
                            <h2>Case Studies</h2>
                            <h4>Real Solutions, Real Results</h4>
                        </div>
                    </div>
                </div>

            </section>
            <div className='background ' style={{ background: "#7F3E98" }} >
                <div className='row text-center'>
                    <div className='col-lg-12 col-sm-12 '>
                        <p className='text-center w-75 mx-auto text-light fs-6  fw-bold'>At Spotcomm, we take pride in delivering tailored network solutions that empower businesses to thrive in a connected world. Our case studies showcase the challenges we’ve tackled and the tangible results we’ve achieved for our clients across diverse industries. Each story highlights how our expertise in managed network services has driven success, whether it’s improving operational efficiency, boosting network security, or enabling seamless scalability.
                        </p>
                    </div>
                </div>

            </div> <br /><br />
            <h2 className='h2 text-center fw-bold'> <span className='' style={{ color: "#7F3E98" }}>Case Studies </span>  By Category</h2><br />

            <div className="case-studies-grid">
                {caseStudies.map((study, index) => (
                    <div className="case-study" key={index}>
                        <div
                            className="case-study-image"
                            style={{ backgroundImage: `url(${study.image})` }}
                        ></div>
                        <div className="case-study-overlay">
                            <h2 className='text-light'>{study.title}</h2>
                        </div>
                    </div>
                ))}
            </div>
            <Demo />
        </div>
    )
}

export default CaseStudies