import React from 'react'
import Header from '../components/Header'
import Demo2 from './Demo2'


const Consulting = () => {
    return (
        <>
            <Header />
            <Demo2 />
        </>
    )
}

export default Consulting